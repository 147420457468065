<template>
    <div class="flex-box">
        <div class="error">
            <h1>404 Page Not Found.</h1>
            <h3>你找错地方啦.</h3>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Error.notFound',
    data () {
        return {}
    },
    methods: {}
}
</script>

<style scoped>
.flex-box {
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 480px;
}

.error {
    flex-direction: column;
    align-items: center;
    margin: auto;

    text-align: center;
}
</style>
